<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Reset Password</h4>
                                    <p>Please enter your new password.</p>
                                </div>
                                <vs-input type="email" placeholder="Email" :maxlength=320 v-model="email_model" name="email" v-validate="'required'" class="w-full mb-6" />
                                <vs-input type="text" placeholder="Verification Code" v-model="formData['verificationCode']" name="verificationCode" v-validate="'required'" class="w-full mb-6" />
                                <vs-input type="password" @input="checkPassword" :maxlength=128 placeholder="New Password" v-model="formData['newPassword']" name="newPassword" v-validate="'required'" class="w-full mb-8" />
                                <span class="text-danger text-sm" v-if="show_password_err_msg">Password Must Be Combination Of 8 Characters Must Includes One Number,Uppercase,Special Character..!!</span>

                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                                    <vs-button type="border" to="/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Go Back To Login</vs-button>
                                    <vs-button class="w-full sm:w-auto"  :disabled="!validateForm" @click="resetPasswordJWT">Reset</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
       valid_password:'',
        show_password_err_msg :false,
        formData: {'email':'', 'verificationCode':'', 'newPassword':''}
    }
  },
  computed: {
    email_model: {
      get () {
        this.formData['email'] = this.$store.getters['auth/getEmail']
        return this.$store.getters['auth/getEmail']
      },
      set (email) {
        this.formData['email'] = email
      }
    },
    validateForm () {
      return !this.errors.any() && this.formData['email'] !== '' && this.formData['verificationCode'] !== ''  && this.valid_password == 1 && this.formData['email'] && this.formData['verificationCode'] && this.formData['newPassword']
    }
  },
  methods: {
     checkPassword() {
      this.password_length = this.formData['newPassword'].length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.formData['newPassword']);
      this.contains_uppercase = /[A-Z]/.test(this.formData['newPassword']);
			this.contains_special_character = format.test(this.formData['newPassword']);
      
      if (this.contains_eight_characters === true && this.contains_special_character === true && this.contains_uppercase === true && this.contains_number === true) {
						this.valid_password = true;		
            this.show_password_err_msg = false	
      } else {
            this.show_password_err_msg = true	
            this.valid_password = false;
      }
    },
    resetPasswordJWT () {

        this.$validator.validateAll().then(result => {
            if (result) {
                this.$vs.loading()
                this.$store.dispatch('auth/resetPasswordJWT', this.formData)
                .then(() => { this.$vs.loading.close() })
                .catch(error => {
                  this.$vs.loading.close()
                  this.$vs.notify({
                    title: 'Alert',
                    text: error.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })
                })
            }
        })

    }
  }
}
</script>
